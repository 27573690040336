import React from "react";
import "./index.scss";
import { Row, Col, Tooltip, Icon, Dropdown, Button, Menu } from "antd";

const MainHeadingBar = props => {
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          props.history.push(`/${props.match.params.domid}/hello-bar`)
        }
      >
        New Hellobar
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() =>
          props.history.push(`/${props.match.params.domid}/hello-bar-timer`)
        }
      >
        New Hellobar Timer
      </Menu.Item>
    </Menu>
  );
  let cta = (
    <div className="actions">
      <Dropdown overlay={menu}>
        <Button>
          Create New
          <Icon type="down" />
        </Button>
      </Dropdown>
    </div>
  );
  if (props.cta) {
    cta = props.cta;
  }
  return (
    <div className="main-heading-bar">
      <Row>
        <Col xs={24} md={12}>
          <h3 className="title">
            {props.title}{" "}
            {props.helpText && (
              <Tooltip title={props.helpText}>
                <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
              </Tooltip>
            )}
          </h3>
          <div className="page-cta">
            {props.subtitle && <p className="subtitle">{props.subtitle}</p>}{" "}
            {props.pageCTA !== undefined && props.pageCTA}
          </div>
        </Col>
        {!props.hideCTA && cta}
      </Row>
    </div>
  );
};

export default MainHeadingBar;
