import React from "react";
import { ReactComponent as ViewsIcon } from "../../../assets/images/icons/overall-view.svg";
import { ReactComponent as TotalClick } from "../../../assets/images/icons/total-click.svg";
import { Card, Icon } from "antd";
import { Link } from "react-router-dom";
import "./TimerCard.scss";

export default props => {
  const element = props.element;
  const types = {
    hellobar: "Hellobar",
    hellobar_timer: "Hellobar + Timer"
  };
  let views = 0;
  if (
    element.element_analytics &&
    element.element_analytics.overall &&
    element.element_analytics.overall.views
  ) {
    views = element.element_analytics.overall.views;
  }
  let clicks = 0;
  if (
    element.element_analytics &&
    element.element_analytics.clicks &&
    element.element_analytics.clicks.overall &&
    element.element_analytics.clicks.overall.views
  ) {
    clicks = element.element_analytics.clicks.overall.views;
  }

  const urls = {
    hellobar: "hello-bar",
    hellobar_timer: "hello-bar-timer"
  };
  return (
    <Card className="timer-card" style={{ margin: "0 0 30px 0" }}>
      <div className="timer-info" style={{ flex: 1 }}>
        <h3>Element Name:</h3>
        <p>{element.element_name}</p>
      </div>
      <div className="timer-info" style={{ flex: 2 }}>
        <h3>Element Type:</h3>
        <p>{types[element.element_type]}</p>
      </div>
      {/* <div className="timer-info" style={{ flex: 1 }}>
        <h3>Expiry:</h3>
        <p>
          {element.element_data.timerType === "date"
            ? `${element.element_data.endDateTime} ${
                element.element_data.timezone.split("|")[0]
              }`
            : `
            ${
              Number(element.element_data.days) === 0
                ? ``
                : `${element.element_data.days}d`
            }
            ${
              Number(element.element_data.hours) === 0
                ? ``
                : `${element.element_data.hours}h`
            }
            ${
              Number(element.element_data.minutes) === 0
                ? ``
                : `${element.element_data.minutes}m`
            }
            ${
              Number(element.element_data.seconds) === 0
                ? ``
                : `${element.element_data.seconds}s`
            }`}
        </p>
      </div> */}
      <div className="timer-actions">
        <ul>
          <li>
            {views} <Icon component={ViewsIcon} />
          </li>
          <li>
            {clicks} <Icon component={TotalClick} />
          </li>
          <li>
            <Link
              to={`/${props.match.params.domid}/edit-${
                urls[element.element_type]
              }/${element._id["$id"]}`}
            >
              <Icon type="edit" /> Edit
            </Link>
          </li>
          <li>
            <Link
              to={`/${props.match.params.domid}/${urls[element.element_type]}/${
                element._id["$id"]
              }`}
            >
              <Icon type="copy" />
              Clone
            </Link>
          </li>
          {!props.hideReport && (
            <li>
              <Link
                to={`/${props.match.params.domid}/analytics/${
                  element._id["$id"]
                }`}
              >
                <Icon type="pie-chart" /> Report
              </Link>
            </li>
          )}
        </ul>
      </div>
    </Card>
  );
};
