import React from 'react';
import './index.scss';

const OnboardingFooter = (props) => {
    return(
        <footer className="onboarding-footer" style={{ marginTop: props.marginTop }}>
            <div>
                <a href="https://letx.co/legals/terms" target="_blank" rel="noreferrer noopener">Terms</a>
                <a href="https://letx.co/legals/privacy" target="_blank" rel="noreferrer noopener">Privacy</a>
                <a href="https://help.Hellobar.io.com/" target="_blank" rel="noreferrer noopener">Help</a>
            </div>

            <p className="copyright-text">
                copyright &copy; 2019 Hellobar.io
            </p>
        </footer>
    );
};

export default OnboardingFooter;