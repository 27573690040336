import React from "react";
import "./index.scss";
import Pieslice from "../../Partials/Pieslice";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const DeviceWiseStats = props => {
  const { device_wise_clicks, device_wise_visitors } = props;

  return (
    <Tabs defaultActiveKey="clicks">
      <TabPane tab="Clicks" key="clicks">
        <Pieslice data={device_wise_clicks} category="device" type="clicks" />
      </TabPane>
      <TabPane tab="Views" key="views">
        <Pieslice data={device_wise_visitors} category="device" type="views" />
      </TabPane>
    </Tabs>
  );
};

export default DeviceWiseStats;
