import React, { Component } from "react";
import "./index.scss";
import { Row, Col, Icon, Tooltip, Card } from "antd";
// import { ChartCard} from 'ant-design-pro/lib/Charts';
import { ReactComponent as TotalLinks } from "../../../../assets/images/icons/total-links.svg";
import { ReactComponent as OverAll } from "../../../../assets/images/icons/overall-view.svg";
// import { ReactComponent as CloakedLinks } from "../../../../assets/images/icons/navLink-black.svg";
import { ReactComponent as TotalClick } from "../../../../assets/images/icons/total-click.svg";
import { ReactComponent as InfoCircle } from "../../../../assets/images/icons/info-cirlce-o.svg";
import { getOverallStats } from "../../../../helpers/ajax/snapshotAjax";
import { formatNumber } from "../../../../helpers/numbers";
import { connect } from "react-redux";

class OverallStats extends Component {
  state = {
    loading: true,
    error: false,
    errorMsg: "",
    elements: 0,
    views: 0,
    clicks: 0
  };

  componentDidMount() {
    getOverallStats(this.props.match.params.domid)
      .then(res => {
        this.setState({
          loading: false,
          elements: res.data.analytics.elements,
          views: res.data.analytics.views,
          clicks: res.data.analytics.clicks
        });
      })
      .catch(e => {
        this.setState({
          error: true,
          errorMsg: e.message
        });
      });
  }

  render() {
    return (
      <div className="overall-stats">
        <Row gutter={24}>
          <Col xs={24} md={6} className="column">
            <Card
              loading={this.state.loading}
              title="Total Elements"
              extra={
                <Tooltip title="Total number of elements in this account">
                  <Icon component={InfoCircle} />
                </Tooltip>
              }
            >
              <p className="label">
                <Icon component={TotalLinks} />{" "}
                <span>{formatNumber(this.state.elements, 0)}</span>
              </p>
            </Card>
          </Col>

          <Col xs={24} md={6} className="column">
            <Card
              loading={this.state.loading}
              title="Total Views"
              extra={
                <Tooltip title="Total number of views on all the elements">
                  <Icon component={InfoCircle} />
                </Tooltip>
              }
            >
              <p className="label">
                <Icon component={OverAll} />{" "}
                <span>{formatNumber(this.state.views, 0)}</span>
              </p>
            </Card>
          </Col>

          <Col xs={24} md={6} className="column">
            <Card
              loading={this.state.loading}
              title="Total Clicks"
              extra={
                <Tooltip title="Total number of Clicks on all the elements">
                  <Icon component={InfoCircle} />
                </Tooltip>
              }
            >
              <p className="label">
                <Icon component={TotalClick} />{" "}
                <span>{formatNumber(this.state.clicks, 0)}</span>
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};
export default connect(mapStateToProps)(OverallStats);
