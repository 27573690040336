import React from "react";
import "./index.scss";
import Pieslice from "../../Partials/Pieslice";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const BrowserWiseStats = props => {
  const { browser_wise_clicks, browser_wise_visitors } = props;

  return (
    <Tabs defaultActiveKey="clicks">
      <TabPane tab="Clicks" key="clicks">
        <Pieslice data={browser_wise_clicks} category="browser" type="clicks" />
      </TabPane>
      <TabPane tab="Views" key="views">
        <Pieslice
          data={browser_wise_visitors}
          category="browser"
          type="views"
        />
      </TabPane>
    </Tabs>
  );
};

export default BrowserWiseStats;
