import React, { Component } from "react";
import "./index.scss";
import { Input, Select, Radio, Switch, Button } from "antd";
import Customisation from "./Customisation";
import FormattingField from "../../Partials/FormattingField";

const { Option } = Select;

export default class GeneralSetting extends Component {
  state = {
    advancedCustomize: false
  };

  onChange = checked => {
    this.setState({
      advancedCustomize: checked
    });
  };
  render() {
    const data = this.props.data;
    return (
      <div className="general-setting">
        <div className="main-header">
          <h1>HELLOBAR DETAILS</h1>
          <p>THE DETAILS YOU WOULD LIKE IN THE HELLOBAR</p>
          <span>(ALL FIELDS MARKED WITH A * ARE REQUIRED)</span>
        </div>
        <div className="general-form">
          <div style={{ marginTop: "20px" }}>
            <p>Campaign Name *</p>
            <Input
              onChange={e =>
                this.props.setValue("element.element_name", e.target.value)
              }
              placeholder="Campaign Name"
              value={data.element_name}
            />
            <span className="spanclass">{this.props.errors.element_name}</span>
          </div>

          <div style={{ marginTop: "20px" }}>
            <p>Template Type * </p>
            <Radio.Group
              onChange={e =>
                this.props.setValue(
                  "element.element_data.templateType",
                  e.target.value
                )
              }
              value={data.element_data.templateType}
            >
              <Radio value={"template1"}> Template 1</Radio>
              <Radio value={"template2"}> Template 2</Radio>
            </Radio.Group>
          </div>

          <FormattingField
            label={<span>Hellobar Text</span>}
            field={props => <Input placeholder="" {...props} />}
            personalizations={[{ tag: "[FNAME]", title: "First Name" }, { tag: "[NAME]", title: "Full Name" }]}
            error={this.props.errors.text}
            value={data.element_data.text}
            onChange={val =>
              this.props.setValue("element.element_data.text", val)
            }
          />

          <div style={{ marginTop: "20px" }}>
            <p>Query Variable to pick personalization tag value from </p>
            <Input
              onChange={e =>
                this.props.setValue(
                  "element.element_data.queryVar",
                  e.target.value
                )
              }
              placeholder="Query Variable"
              value={data.element_data.queryVar}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Fallback text to personalization tag</p>
            <Input
              onChange={e =>
                this.props.setValue(
                  "element.element_data.fallback",
                  e.target.value
                )
              }
              placeholder="Fallback Text"
              value={data.element_data.fallback}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <p>Hellobar Position * </p>
            <Select
              value={data.element_data.position}
              onChange={value =>
                this.props.setValue("element.element_data.position", value)
              }
            >
              <Option value="bottom">Bottom</Option>
              <Option value="top">Top</Option>
            </Select>
          </div>

          <div style={{ marginTop: "20px" }}>
            <p>CTA Text * </p>
            <Input
              placeholder="CTA Text"
              value={data.element_data.buttonText}
              onChange={e =>
                this.props.setValue(
                  "element.element_data.buttonText",
                  e.target.value
                )
              }
            />
            <span className="spanclass">{this.props.errors.buttonText}</span>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className="evergreen">
              <div className="inner-timer">
                <p style={{ width: "100%" }}>CTA Action * </p>
                <Select
                  value={data.element_data.buttonAction}
                  onChange={value =>
                    this.props.setValue(
                      "element.element_data.buttonAction",
                      value
                    )
                  }
                >
                  <Option value={"redirect"}>Redirect</Option>
                  <Option value={"redirectInNewTab"}>
                    Redirect in new tab
                  </Option>
                  <Option value={"scroll"}>Scroll</Option>
                </Select>
              </div>
              <div style={{ flex: ".2" }} />
              {data.element_data.buttonAction === "redirect" ||
              data.element_data.buttonAction === "redirectInNewTab" ? (
                <div className="inner-timer">
                  <p style={{ width: "100%" }}>Redirect URL *</p>
                  <Input
                    onChange={e =>
                      this.props.setValue(
                        "element.element_data.redirectUrl",
                        e.target.value
                      )
                    }
                    value={data.element_data.redirectUrl}
                  />
                  <div style={{ width: "100%", color: "red" }}>
                    <span className="spanClass">
                      {this.props.errors.redirectUrl}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              {data.element_data.buttonAction === "scroll" ? (
                <div className="inner-timer">
                  <p style={{ width: "100%" }}>XPath to element * </p>
                  <Input
                    onChange={e =>
                      this.props.setValue(
                        "element.element_data.scrollTo",
                        e.target.value
                      )
                    }
                    value={data.element_data.scrollTo}
                  />
                  <div style={{ width: "100%", color: "red" }}>
                    <span className="spanClass">
                      {this.props.errors.scrollTo}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div style={{ marginTop: "20px", display: "flex" }}>
            <Switch
              checked={this.state.advancedCustomize}
              onChange={checked => this.onChange(checked)}
            />
            <p style={{ marginLeft: "10px" }}> Styling Customisation </p>
          </div>

          {this.state.advancedCustomize ? (
            <Customisation data={data} setValue={this.props.setValue} />
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px", display: "flex" }}>
          <Button onClick={() => this.props.saveGeneralSettings()}>Next</Button>
        </div>
      </div>
    );
  }
}
