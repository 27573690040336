import React from "react";
import { Table, Tabs } from "antd";
import { Chart } from "react-google-charts";
import "./index.scss";
import NoData from "../../Partials/NoData";

const { TabPane } = Tabs;

const CountryWiseStats = props => {
  const { country_wise_clicks, country_wise_visitors } = props;

  const tableData = {};
  country_wise_clicks.forEach(element => {
    if (tableData[element[0]] === undefined) {
      tableData[element[0]] = {
        clicks: 0,
        visitors: 0
      };
    }
    tableData[element[0]].clicks = element[1];
  });

  const visitorTableData = {};
  country_wise_visitors.forEach(element => {
    if (visitorTableData[element[0]] === undefined) {
      visitorTableData[element[0]] = {
        clicks: 0,
        visitors: 0
      };
    }
    visitorTableData[element[0]].visitors = element[1];
  });

  let tableArray = { ...tableData };
  delete tableArray["Country"];
  tableArray = Object.keys(tableArray).map(country => {
    return {
      country,
      visitors: tableArray[country]["visitors"],
      clicks: tableArray[country]["clicks"]
    };
  });

  let visitorTableArray = { ...visitorTableData };
  delete visitorTableArray["Country"];
  visitorTableArray = Object.keys(visitorTableArray).map(country => {
    return {
      country,
      visitors: visitorTableArray[country]["visitors"],
      clicks: visitorTableArray[country]["clicks"]
    };
  });

  return (
    <Tabs defaultActiveKey="clicks">
      <TabPane tab="Clicks" key="clicks">
        <div className="countrywise-stats-container">
          {country_wise_clicks.length <= 1 ? (
            <NoData
              heading="Not enough data!"
              content="We could not find enough data to build a graph!"
            />
          ) : (
            <Chart
              height={500}
              chartType="GeoChart"
              data={country_wise_clicks}
              rootProps={{ "data-testid": "1" }}
              width="99%"
            />
          )}

          <div className="table">
            <div className="content-container">
              <Table
                columns={[
                  {
                    title: "Country",
                    dataIndex: "country",
                    key: "country",
                    sorter: (a, b) =>
                      (a.country > b.country) - (a.country < b.country)
                  },
                  {
                    title: "Clicks",
                    dataIndex: "clicks",
                    key: "clicks",
                    sorter: (a, b) => a.clicks - b.clicks
                  }
                ]}
                rowKey={r => r.country}
                pagination={false}
                dataSource={tableArray}
              />
            </div>
          </div>
        </div>
      </TabPane>
      <TabPane tab="Views" key="views">
        <div className="countrywise-stats-container">
          {country_wise_visitors.length <= 1 ? (
            <NoData
              heading="Not enough data!"
              content="We could not find enough data to build a graph!"
            />
          ) : (
            <Chart
              height={500}
              chartType="GeoChart"
              data={country_wise_visitors}
              rootProps={{ "data-testid": "1" }}
              width="99%"
            />
          )}

          <div className="table">
            <div className="content-container">
              <Table
                columns={[
                  {
                    title: "Country",
                    dataIndex: "country",
                    key: "country",
                    sorter: (a, b) =>
                      (a.country > b.country) - (a.country < b.country)
                  },
                  {
                    title: "Visitors",
                    dataIndex: "visitors",
                    key: "visitors",
                    sorter: (a, b) => a.visitors - b.visitors
                  }
                ]}
                rowKey={r => r.country}
                pagination={false}
                dataSource={visitorTableArray}
              />
            </div>
          </div>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default CountryWiseStats;
