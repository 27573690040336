import React, { Component } from 'react';
import './index.scss';
import { Form, Icon, Input, Button, Card, Checkbox, message } from 'antd';
import { ReactComponent as Logo } from '../../../assets/images/logos/logo.svg';
import { Link } from 'react-router-dom';
import OnboardingFooter from '../../Dashboard/Partials/OnboardingFooter';
import { connect } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

class Signup extends Component {
	state = {
		activation_codes: [{ id: Date.now(), code: "", error: '' }],
	};

	handleSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFields((err, values) => {
			if (!err) {
				values.user_fname = `${values.firstname} ${values.lastname}`;
				values.activation_codes = this.state.activation_codes.map(ac => ac.code);
				axios({
					url: '/login/signup_invite',
					method: 'post',
					data: {
						...values
					}
				}).then((res) => {
					if (res.data.success === 1) {
						this.props.history.push('/switch-account');
					} else {
						if (res.data.codes) {
							this.setState({
								activation_codes: this.state.activation_codes.map(ac => {
									if (res.data.codes[ac.code] && res.data.codes[ac.code].success !== 1) {
										return { ...ac, error: res.data.codes[ac.code].msg }
									} else {
										return ac;
									}
								})
							})
						}
						message.error(res.data.msg);
					}
				}).catch((e) => {
					message.error(e.message);
				});
			}
		});
	}

	componentDidMount() {
		document.title = this.props.title;
		const params = queryString.parse(this.props.location.search);
		if (params.activation_code) {
			this.setState({
				activation_codes: [{ id: Date.now(), code: params.activation_code, error: '' }],
			})
		}
	}

	componentWillReceiveProps(props) {
		document.title = props.title;
	}

	getNextIdForActivationCode() {
		const ids = this.state.activation_codes.map(ac => ac.id);
		return Math.max(...ids) + 1;
	}

	validateActivationCode(id) {
		const code = this.state.activation_codes.find(ac => ac.id === id);
		axios({
			url: '/login/validate_activation_code',
			method: 'post',
			data: {
				activation_code: code.code
			}
		}).then((res) => {
			if (res.data.success === 1) {
				this.setState({
					activation_codes: this.state.activation_codes.map((ac) => {
						if (id === ac.id) {
							return { ...ac, error: '' }
						} else {
							return ac;
						}
					})
				}, () => {
					if (this.state.activation_codes.length < 5) {
						this.setState({
							activation_codes: [...this.state.activation_codes, { code: '', error: '', id: this.getNextIdForActivationCode() }]
						})
					}
				})
			} else {
				this.setState({
					activation_codes: this.state.activation_codes.map(ac => {
						if (ac.id === id) {
							return { ...ac, error: res.data.msg };
						} else {
							return ac;
						}
					})
				})
			}
		})
	}

	removeActivationCode(id) {
		this.setState({
			activation_codes: this.state.activation_codes.filter(ac => id !== ac.id)
		})
	}

	setCodeValue(code, id) {
		this.setState({
			activation_codes: this.state.activation_codes.map(ac => {
				if (id === ac.id) {
					return { ...ac, code }
				} else {
					return ac;
				}
			})
		})
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { logged_in, domain } = this.props.auth;

		if (logged_in) this.props.history.push(`/${domain.id}`);

		return (
			<div className="signup-form-container">
				<Logo className="logo" />

				<Card className="signup-card">
					<h2 className="title">Let's set up your account.</h2>
					<h5 className="sub-title">Get started with special plan of Hellobar.io.</h5>

					<Form onSubmit={this.handleSubmit} className="signup-form">
						<div className="flex">
							<Form.Item>
								<span className="label">First Name</span>
								{getFieldDecorator('firstname', {
									rules: [{ required: true, message: 'Please input your first name!' }],
								})(
									<Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Your First Name" />
								)}
							</Form.Item>

							<Form.Item>
								<span className="label">Last Name</span>
								{getFieldDecorator('lastname', {
									rules: [{ required: true, message: 'Please input your last name!' }],
								})(
									<Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Your Last Name" />
								)}
							</Form.Item>
						</div>

						<Form.Item>
							<span className="label">Email Address</span>
							{getFieldDecorator('user_email', {
								rules: [
									{ required: true, message: 'Please input your email!' },
									{ type: 'email', message: 'Please enter a valid email address.' }
								],
							})(
								<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Your Email Id" />
							)}
						</Form.Item>

						<Form.Item>
							<span className="label">Set Up A Password</span>
							{getFieldDecorator('user_pass', {
								rules: [
									{ required: true, message: 'Please enter a valid password!' },
								],
							})(
								<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Your Password" />
							)}
						</Form.Item>

						{this.state.activation_codes.map((ac, index) => (
							<div className={`ant-form-item-control ${ac.error ? 'has-error' : ''}`} key={ac.id}>
								<span className="label">Activation Code {index + 1}</span>
								<Input prefix={<Icon type="barcode" style={{ color: 'rgba(0,0,0,.25)' }} />} value={ac.code} onChange={(e) => this.setCodeValue(e.target.value, ac.id)} onBlur={() => this.validateActivationCode(ac.id)} placeholder="Your Activation Code" style={{ width: "calc(100% - 24px)", marginRight: "10px" }} />
								{this.state.activation_codes.length > 1 && <span onClick={() => this.removeActivationCode(ac.id)}><Icon type="plus" style={{ transform: "rotate(45deg)", cursor: "pointer" }} /></span>}
								{ac.error && <div className="ant-form-explain">{ac.error}</div>}
							</div>
						))}

						<Form.Item>
							{getFieldDecorator('user_receive_updates', {
								rules: [],
								valuePropName: 'checked',
								initialValue: true,
							})(
								<Checkbox>I'd like to receive product updates, offers and tips via email</Checkbox>
							)}
							<span className="label checkbox-label">
								You may opt out at any time using the Unsubscribe link provided in your emails.
                            </span>
						</Form.Item>

						<div className="flex">
							<p className="label disclaimer">
								By continuing, I acknowledge that I've read and I agree to the <a href="https://letx.co/terms">Terms of Service</a>, <a href="https://letx.co/privacy-policy">Privacy Policy</a> and <a href="https://letx.co/dpa">Data Protection Addendum</a>.
                            </p>

							<Button type="primary" htmlType="submit" className="form-button">
								Continue
                            </Button>
						</div>
					</Form>

					<p className="bottom-link">
						Already have an account? <Link to='/login'>Login.</Link>
					</p>
				</Card>

				<OnboardingFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth
	};
};

export default connect(mapStateToProps)(Form.create()(Signup));