import React, { Component } from "react";
import TimerCard from "./TimerCard";
import "./Links.scss";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Col,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Pagination
} from "antd";
import { connect } from "react-redux";
import { getTimers } from "../../../helpers/ajax/linksAjax";
import NoData from "../Partials/NoData";
import { ReactComponent as Placeholder } from "../../../assets/images/icons/no-data.svg";

class Links extends Component {
  state = {
    loading: true,
    error: false,
    errorMsg: "",
    links: [],
    sort: { element_created: -1 },
    paging: {
      current: 1,
      total: 0,
      pageSize: 10
    }
  };

  sort(order) {
    this.setState(
      {
        sort: order
      },
      () => this.fetch()
    );
  }

  fetch = () => {
    this.setState(
      {
        links: [],
        loading: true
      },
      () => {
        getTimers(
          this.props.auth.domain.id,
          this.state.paging.current,
          this.state.paging.pageSize,
          this.state.sort
        ).then(res => {
          this.setState({
            loading: false,
            links: res.data.elements,
            paging: {
              ...this.state.paging,
              total: res.data.total
            }
          });
        });
      }
    );
  };

  componentDidMount = () => {
    this.fetch();
  };

  goToPage(page) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          current: page
        }
      },
      () => this.fetch()
    );
  }

  render() {
    const { links, loading, error, errorMsg, sort } = this.state;

    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.sort({ element_created: 1 })}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.sort({ element_created: -1 })}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = children => (
      <div className="campaigns-container">
        <MainHeadingBar
          title="Elements"
          subtitle={
            <span className="sort">
              Sort By:{" "}
              <Dropdown overlay={sortMenu}>
                {sort.element_created === -1 ? (
                  <Button size="default">
                    Desc. Order <Icon type="down" />
                  </Button>
                ) : (
                  <Button size="default">
                    Asc. Order <Icon type="down" />
                  </Button>
                )}
              </Dropdown>
            </span>
          }
          {...this.props}
        />

        {children}
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return PageWrapper(
      <div className="links">
        <Row gutter={30}>
          {links.length ? (
            links.map(link => (
              <Col xs={12} className="column" key={link._id["$id"]}>
                <TimerCard {...this.props} element={link} />
              </Col>
            ))
          ) : (
            <NoData image={Placeholder} heading="No Elements Found!" />
          )}
        </Row>
        <Pagination
          style={{ marginTop: 30 }}
          onChange={page => this.goToPage(page)}
          current={this.state.paging.current}
          total={this.state.paging.total}
          pageSize={this.state.paging.pageSize}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(Links);
