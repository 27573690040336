import Axios from "axios";

export const toggleElementArchive = (domid, elementid) => {
  return Axios({
    url: "/elements/toggle_element_archive",
    method: "post",
    data: {
      domid,
      elementid
    }
  });
};

export const getElements = (domid, archived, page, pageSize, order) => {
  return Axios({
    url: "/elements/get_elements",
    method: "post",
    data: {
      domid,
      archived,
      page,
      pageSize,
      order
    }
  });
};

export const newElement = (domid, elementid = undefined) => {
  return Axios({
    url: "/elements/new_element",
    method: "post",
    data: {
      domid,
      elementid
    }
  });
};

export const scrapeURL = url => {
  return Axios({
    url: "/elements/scrape_url",
    method: "post",
    data: {
      url
    }
  });
};

export const scrapeTwitterURL = params => {
  const paramString = Object.keys(params)
    .map(k => `${k}=${params[k]}`)
    .join("&");
  return Axios({
    url: "/elements/scrape_twitter_url?" + paramString,
    method: "get"
  });
};

export const scrapePinterestURL = url => {
  return Axios({
    url: "/elements/scrape_pinterest_url",
    method: "post",
    data: {
      url
    }
  });
};

export const saveElement = (domid, element) => {
  return Axios({
    url: "/elements/save_element",
    method: "post",
    data: {
      domid,
      element
    }
  });
};

export const getPreview = element => {
  return Axios({
    url: "/elements/generate_preview",
    method: "post",
    data: {
      element
    }
  });
};

export const getElementAnalytics = (domid, elementid, startDate, endDate) => {
  return Axios({
    url: "/elements/get_element_analytics",
    method: "post",
    data: {
      domid,
      elementid,
      startDate,
      endDate
    }
  })
    .then(res => {
      return {
        analytics: res.data.analytics,
        loading: false
      };
    })
    .catch(e => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false
      };
    });
};

export const getRecords = (domid, elementid, type) => {
  return Axios({
    url: "/elements/get_records",
    method: "post",
    data: {
      domid,
      elementid,
      type
    }
  });
};

export const getEmailRecords = (domid, elementid, search, limit, offset) => {
  return Axios({
    url: "/elements/get_records_email",
    method: "post",
    data: {
      domid,
      elementid,
      search,
      limit,
      offset
    }
  });
};

export const getClickRecords = (domid, elementid, search, limit, offset) => {
  return Axios({
    url: "/elements/get_records_clicks",
    method: "post",
    data: {
      domid,
      elementid,
      search,
      limit,
      offset
    }
  });
};
