import React, { Component } from "react";
import "./index.scss";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { Card, Tabs, Modal, Icon, Button, message } from "antd";
import GeneralSetting from "./GeneralSetting";
import { set } from "lodash";
import Trigger from "./Trigger";
import axios from "axios";
import { newElement } from "../../../helpers/ajax/elementsAjax";
import "./index.scss";

const { TabPane } = Tabs;

export default class HelloBarTimer extends Component {
  state = {
    errors: {},
    activeTab: "1",
    element: {
      element_name: "",
      element_data: {
        templateType: "template1",
        text: "",
        position: "top",
        buttonText: "",
        buttonAction: "redirect",
        scrollTo: "",
        redirectUrl: "",
        timerTemplate: "template1",
        timerType: "date",
        endDateTime: "",
        timezone: "(UTC+05:30) New Delhi|Asia/Kolkata",
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
        postExpiryAction: "hide",
        postExpiryRedirectUrl: "",
        labels: {
          days: "Days",
          hours: "Hours",
          minutes: "Minutes",
          seconds: "Seconds"
        },
        timerFontSize: "25",
        timerColor: "#ffffff",
        timerFont: "Roboto",
        labelFontSize: "10",
        labelColor: "#ffffff",
        labelFont: "Quicksand",
        textFontSize: "18",
        textColor: "#ffffff",
        textFont: "Quicksand",
        bgColor: "#fd0000",
        buttonTextFontSize: "20",
        buttonTextColor: "#ffffff",
        buttonTextFont: "Quicksand",
        buttonBgColor: "#1cbe06",
        highlightFontSize: "25",
        highlightColor: "#000000",
        highlightFont: "Quicksand",
        highlightBgColor: "#f4ff00",
        mobileOptimised: 0,
        floating: 0,
        tag: "",
        queryVar: "",
        fallback: ""
      },
      element_type: "hellobar_timer",
      element_displayurl: [
        {
          id: Date.now(),
          type: "CONTAINS",
          data: ""
        }
      ],
      element_excludeurl: [],
      element_referrer: []
    },
    successModal: {
      visible: false,
      id: ""
    },
    loading: false,
    saving: false,
    edit: false
  };

  componentDidMount() {
    const elementid = this.props.match.params.elementid;
    const edit = this.props.editElement === true ? true : false;
    if (elementid && elementid.match(/[a-fA-F0-9]{24}/)) {
      this.setState(
        {
          loading: true
        },
        () => {
          newElement(this.props.match.params.domid, elementid).then(res => {
            const updates = { loading: false };
            if (res.data.success === 1) {
              updates.element = res.data.element;
            }
            if (edit) {
              updates.edit = edit;
            }
            this.setState(updates);
          });
        }
      );
    }
  }

  addTrigger = title => {
    if (title === "Include") {
      this.setState(state => ({
        element: {
          ...state.element,
          element_displayurl: [
            ...this.state.element.element_displayurl,
            { id: Date.now(), type: "CONTAINS", data: "" }
          ]
        }
      }));
    } else if (title === "Exclude") {
      this.setState(state => ({
        element: {
          ...state.element,
          element_excludeurl: [
            ...this.state.element.element_excludeurl,
            { id: Date.now(), type: "CONTAINS", data: "" }
          ]
        }
      }));
    } else if (title === "Referrer") {
      this.setState(state => ({
        element: {
          ...state.element,
          element_referrer: [
            ...this.state.element.element_referrer,
            { id: Date.now(), type: "CONTAINS", data: "" }
          ]
        }
      }));
    }
  };

  deleteTrigger = (id, title) => {
    if (title === "Include") {
      if (this.state.element.element_displayurl.length > 1) {
        const newTrigger = this.state.element.element_displayurl.filter(
          l => l.id !== id
        );
        this.setState(state => ({
          element: {
            ...state.element,
            element_displayurl: newTrigger
          }
        }));
      }
    } else if (title === "Exclude") {
      const newTrigger = this.state.element.element_excludeurl.filter(
        l => l.id !== id
      );
      this.setState(state => ({
        element: {
          ...state.element,
          element_excludeurl: newTrigger
        }
      }));
    } else if (title === "Referrer") {
      const newTrigger = this.state.element.element_referrer.filter(
        l => l.id !== id
      );
      this.setState(state => ({
        element: {
          ...state.element,
          element_referrer: newTrigger
        }
      }));
    }
  };

  setValue = (key, val) => {
    const currentState = { ...this.state };
    this.setState(set(currentState, key, val));
  };

  validateGeneralSettings = (cb = () => {}) => {
    const element = this.state.element;
    const errors = {};
    if (element.element_name === "") {
      errors.element_name = "Please enter a valid element name!";
    }
    if (element.element_data.text === "") {
      errors.text = "This is a required field!";
    }
    if (element.element_data.buttonText === "") {
      errors.buttonText = "This is a required field!";
    }
    if (
      element.element_data.buttonAction === "redirect" ||
      element.element_data.buttonAction === "redirectInNewTab"
    ) {
      if (element.element_data.redirectUrl === "") {
        errors.redirectUrl = "This is a required field!";
      }
    }
    if (element.element_data.buttonAction === "scroll") {
      if (element.element_data.scrollTo === "") {
        errors.scrollTo = "This is a required field!";
      }
    }
    if (element.element_data.timerType === "date") {
      if (element.element_data.endDateTime === "") {
        errors.endDateTime = "This is a required field!";
      }
    }
    if (element.element_data.postExpiryAction === "redirect") {
      if (element.element_data.postExpiryRedirectUrl === "") {
        errors.postExpiryRedirectUrl = "This is a required field!";
      }
    }
    if (
      element.element_data.timerType === "cookie" ||
      element.element_data.timerType === "evergreen"
    ) {
      if (element.element_data.days === undefined) {
        errors.days = "This is a required field!";
      }
      if (element.element_data.hours === undefined) {
        errors.hours = "This is a required field!";
      }
      if (element.element_data.minutes === undefined) {
        errors.minutes = "This is a required field!";
      }
      if (element.element_data.seconds === undefined) {
        errors.seconds = "This is a required field!";
      }
    }
    if (element.element_data.labels.days === "") {
      errors.labelDays = "This is a required field!";
    }
    if (element.element_data.labels.hours === "") {
      errors.labelHours = "This is a required field!";
    }
    if (element.element_data.labels.minutes === "") {
      errors.labelMinutes = "This is a required field!";
    }
    if (element.element_data.labels.seconds === "") {
      errors.labelSeconds = "This is a required field!";
    }

    const updates = {
      errors
    };

    if (Object.keys(errors).length > 0) {
      updates.activeTab = "1";
    }

    this.setState(updates, () => {
      if (Object.keys(errors).length === 0) {
        cb();
      }
    });
  };

  saveGeneralSettings = () => {
    this.validateGeneralSettings(() => {
      this.setState({
        activeTab: "2"
      });
    });
  };

  validateTriggerSettings = (ct = () => {}) => {
    const element = this.state.element;
    const errors = {};
    element.element_displayurl.map(l =>
      l.data === ""
        ? (errors.element_displayurl = "This field is required")
        : ""
    );

    if (element.element_excludeurl.length >= 1) {
      element.element_excludeurl.map(x =>
        x.data === ""
          ? (errors.element_excludeurl = "This field is required")
          : ""
      );
    }
    if (element.element_referrer.length >= 1) {
      element.element_referrer.map(r =>
        r.data === ""
          ? (errors.element_referrer = "This field is required")
          : ""
      );
    }
    this.setState(
      {
        errors
      },
      () => {
        if (Object.keys(errors).length === 0) {
          ct();
        }
      }
    );
  };

  saveData = () => {
    this.validateGeneralSettings(() => {
      this.validateTriggerSettings(() => {
        const data = {
          element: this.state.element,
          domid: this.props.match.params.domid
        };
        if (this.state.edit) {
          data.elementid = this.props.match.params.elementid;
        }
        this.setState(
          {
            saving: true
          },
          () => {
            axios({
              url: "/elements/save_element",
              method: "post",
              data
            })
              .then(res => {
                this.setState({
                  saving: false
                });
                return res;
              })
              .then(res => {
                if (res.data.success === 1) {
                  this.setState({
                    successModal: {
                      ...this.state.successModal,
                      visible: true,
                      id: res.data.id
                    }
                  });
                } else {
                  message.error(res.data.msg);
                }
              })
              .catch(e => {
                console.log(e);
                message.error(e.message);
              });
          }
        );
      });
    });
  };

  closeSuccessModal = () => {
    this.props.history.push(`/${this.props.match.params.domid}/elements`);
  };

  render() {
    return (
      <div className="create-new-timer">
        <MainHeadingBar
          title="New Hellobar with Timer "
          cta={<div />}
          {...this.props}
        />
        <div className="timerContent">
          <Card className="timer-tabs">
            <Tabs
              tabPosition="left"
              activeKey={this.state.activeTab}
              onChange={ac => this.setState({ activeTab: ac })}
            >
              <TabPane tab="General Setting" key="1">
                <GeneralSetting
                  {...this.props}
                  setValue={this.setValue}
                  data={this.state.element}
                  errors={this.state.errors}
                  saveGeneralSettings={this.saveGeneralSettings}
                />
              </TabPane>
              <TabPane tab="Trigger" key="2">
                <Trigger
                  {...this.props}
                  setValue={this.setValue}
                  data={this.state.element}
                  addTrigger={this.addTrigger}
                  deleteTrigger={this.deleteTrigger}
                  saveData={this.saveData}
                  saving={this.state.saving}
                  errors={this.state.errors}
                />
              </TabPane>
            </Tabs>
          </Card>
          <Modal
            title={null}
            footer={null}
            visible={this.state.successModal.visible}
            onOk={this.closeSuccessModal}
            onCancel={this.closeSuccessModal}
          >
            <div style={{ textAlign: "center" }}>
              <Icon
                type="check-circle"
                theme="filled"
                style={{ color: "#60d155", fontSize: "5rem" }}
              />
              <br />
              <br />
              <h3>Element Created Successfully!</h3>
              <br />
              <br />
              <Button
                className="btn-green"
                type="success"
                onClick={this.closeSuccessModal}
              >
                Continue <Icon type="arrow-right" />
              </Button>
            </div>
          </Modal>
          <div className="timer Preview" />
        </div>
      </div>
    );
  }
}
