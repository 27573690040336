import React from "react";
import { Tabs, Skeleton } from "antd";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import NoData from "../../../Dashboard/Partials/NoData";
import { ReactComponent as NoGraph } from "../../../../assets/images/icons/no-graph.svg";
import { modifyDaywiseData } from "../../../../helpers/analytics";
import "./index.scss";

const DayWiseStats = props => {
  let { day_wise_clicks, day_wise_visitors } = props;
  const day_wise_clicks_cols = {
    date: { alias: "date", tickCount: 10 },
    value: { alias: "Clicks:" }
  };
  const day_wise_visitors_cols = {
    date: { alias: "date", tickCount: 10 },
    value: { alias: "Views:" }
  };

  const tooltip = (
    <Tooltip
      crosshairs={null}
      containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
      itemTpl='<tr class="g2-tooltip-list-item"><td style="color:{color}"><span class="g2-tooltip-list-item-dot"></span>{name}</td><td>{value}</td></tr>'
      offset={50}
      g2-tooltip={{
        position: "absolute",
        visibility: "hidden",
        border: "1px solid #000",
        borderRadius: "4px",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        color: "#fff",
        opacity: "1",
        padding: "1rem 1.25rem",
        transition: "top 200ms,left 200ms",
        fontSize: "14px"
      }}
      g2-tooltip-list={{
        marginTop: "0.5rem"
      }}
    />
  );
  const placeholder = (
    <NoData
      heading="Not enough data!"
      content="We could not find enough data to build a graph!"
      image={NoGraph}
    />
  );

  day_wise_clicks = modifyDaywiseData(day_wise_clicks);
  day_wise_visitors = modifyDaywiseData(day_wise_visitors);
  const ClicksComponent = props.loading ? (
    <Skeleton active />
  ) : (
    <Chart
      height={300}
      data={day_wise_clicks}
      scale={day_wise_clicks_cols}
      forceFit
      placeholder={placeholder}
    >
      <Axis
        name="date"
        visible={day_wise_clicks.length < 15 ? true : false}
        tickLine={{
          lineWidth: 2,
          stroke: "#E9E9E9",
          length: 7
        }}
        line={{
          stroke: "#E9E9E9"
        }}
        label={{
          formatter: text => {
            return text.split(",")[0];
          }
        }}
      />
      <Axis
        name="value"
        grid={{
          align: "bottom",
          type: "line",
          lineStyle: {
            stroke: "#E9E9E9",
            lineWidth: 1,
            lineDash: [4, 4]
          }
        }}
      />
      {tooltip}
      <Geom
        type="interval"
        position="date*value"
        color="l (270) 0:rgba(255, 47, 47, 1) .5:rgba(255, 47, 47, 1) 1:rgba(255, 47, 47, 1)"
        style={{
          shadowColor: "l (270) 0:rgba(21, 146, 255, 0)",
          shadowBlur: 60,
          shadowOffsetY: 6
        }}
        active={true}
      />
    </Chart>
  );
  const ViewsComponent = props.loading ? (
    <Skeleton active />
  ) : (
    <Chart
      height={300}
      data={day_wise_visitors}
      scale={day_wise_visitors_cols}
      forceFit
      placeholder={placeholder}
    >
      <Axis
        name="date"
        visible={day_wise_visitors.length < 15 ? true : false}
        tickLine={{
          lineWidth: 2,
          stroke: "#E9E9E9",
          length: 7
        }}
        line={{
          stroke: "#E9E9E9"
        }}
        label={{
          formatter: text => {
            return text.split(",")[0];
          }
        }}
      />
      <Axis
        name="value"
        grid={{
          align: "bottom",
          type: "line",
          lineStyle: {
            stroke: "#E9E9E9",
            lineWidth: 1,
            lineDash: [4, 4]
          }
        }}
      />
      {tooltip}
      <Geom
        type="interval"
        position="date*value"
        color="l (270) 0:rgba(255, 47, 47, 1) .5:rgba(255, 47, 47, 1) 1:rgba(255, 47, 47, 1)"
        style={{
          shadowColor: "l (270) 0:rgba(21, 146, 255, 0)",
          shadowBlur: 60,
          shadowOffsetY: 6
        }}
        active={true}
      />
    </Chart>
  );

  return (
    <Tabs
      defaultActiveKey="1"
      className="daywise-stats-tabs"
      tabBarExtraContent={props.addons}
    >
      <Tabs.TabPane tab="Daywise Clicks" key="1">
        {ClicksComponent}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Daywise Views" key="2">
        {ViewsComponent}
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DayWiseStats;
